import "./Style.scss";
import Button from "./Button";

function JoinUs(){
    return(
        <div className="section joinUsSection">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-xl-4">
                        <h1>Join <span className="colorWhite">the squad</span></h1>
                        <div className="descriptionText">
                            Join forces with our passionate iGaming team and revolutionize the experiences together.
                        </div>
                        <Button link={process.env.PUBLIC_URL + "/contact"} name="Let's Build Together" className="filled blueTheme" style={{marginTop: "20px", width: "190px", height: "50px"}}></Button>
                    </div>
                    <div className="banner col-md-6 col-xl-8"></div>
                </div>
            </div>
        </div>
    );
}
export default JoinUs;