import { Link } from "react-router-dom";

function Button(attributes){
    return(
        <Link to={(attributes.link)?attributes.link:""}>
            <button type="button" className={"btn customButton" + " " + attributes.className} style={attributes.style}>
                {attributes.name}
            </button>
        </Link>
    );
}
export default Button;