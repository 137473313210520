import "./Style.scss";
import Header from "../components/Header";
import Button from "../components/Button";
import Hero from "../components/Hero";
import JoinUs from "../components/JoinUs";
import WhyChooseUs from "../components/WhyChooseUs";
import GameCards from "./GameCards";
import Footer from "../components/Footer";


function Home(){
    return(
        <div className="page">
            <Header active="home" />

            <Hero bannerName="homePageHero"></Hero>

            <div className="seperator"><div className="container"></div></div>
            
            <div className="section">
                <div className="container">
                    <div className="descriptionText" style={{fontWeight: "bold", fontSize:"20px", color:"#777"}}>
                        We are a provider of innovative gaming solutions. Our expertise lies in creating engaging,
                        high-quality casino games that drive player satisfaction and maximize revenue for our partners.
                    </div>

                    <GameCards showAll="false" showDescription="false"></GameCards>
            
                    <Button name="View All" link={process.env.PUBLIC_URL + "/games"} className="filled blackTheme" style={{marginTop: "40px", width: "120px", height: "50px"}}></Button>
                </div>
            </div>

            <div className="seperator"><div className="container"></div></div>

            <div className="section serviceSection">
                <div className="container">
                    <h1>What <span className="colorWhite">we do</span></h1>
                    <div className="descriptionText" style={{marginBottom:"30px"}}>
                        We're not just a gaming company; we're a squad of gaming enthusiasts on a mission to create epic fun.
                        We're the mad scientists of entertainment, mixing code, creativity, and caffeine to concoct gaming gold.
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-12 col-md-1"></div>

                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/icon_GameDvelopment.svg" />
                                <div className="cardTitle">
                                    Game development
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/Icon_GamingFramework.svg" />                                
                                <div className="cardTitle">
                                    Gaming Framework
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/Icon_Hexagon.svg" />                                
                                <div className="cardTitle">
                                    Game simulators
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/Icon_Hexagon.svg" />                                    
                                <div className="cardTitle">
                                    Social casino solutions
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/Icon_Hexagon.svg" />                                    
                                <div className="cardTitle">
                                    Math magic
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-1"></div>
                    </div>

                    <div>
                        <Button link={process.env.PUBLIC_URL + "/services"} name="View all services" className="filled blackTheme" style={{marginTop:"30px", width: "190px", height: "50px"}}></Button>
                    </div>
                </div>
            </div>

            <div className="seperator"><div className="container"></div></div>
            
            <WhyChooseUs></WhyChooseUs>

            <div className="seperator"><div className="container"></div></div>

            <JoinUs></JoinUs>

            <Footer />
        </div>
    );
}
export default Home;