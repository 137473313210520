import "./Style.scss";


function SocialMedia(attributes){
    return(
        <div>
            {attributes.heading? (<h4 className="text-uppercase" style={{color:"#AAA"}}>{attributes.heading}</h4>) : ""}
            
            <ul className="socialMediaLinks d-flex m-auto m-md-0" style={{width:"fit-content"}}>
                <li>
                    <i className="bi bi-facebook"></i>
                </li>
                <li>
                    <i className="bi bi-instagram"></i>
                </li>
                <li>
                    <i className="bi bi-linkedin"></i>
                </li>
                {/* <li>
                    <i className="bi bi-twitter"></i>
                </li> */}
                <li>
                    <i className="bi bi-youtube"></i>
                </li>
            </ul>
        </div>
    );
}

export default SocialMedia;