import "./Style.scss";
import Button from "./Button";

function HeroHome(props){
    return(
        <div className={((props.bannerName)?(props.bannerName + " "):"") + "hero paddingB0"}>
            <div className="container">
                <h1>Building the foundation<br/><span className="colorWhite">for exceptional gaming</span></h1>
                
                <div className="descriptionText" style={{marginTop: "20px"}}>
                    While graphics and visuals are crucial, we believe that a solid framework is the unsung hero of gaming.<br></br>
                    We're passionate about providing the building blocks for exceptional gaming
                </div>
                <div className="bannerImage">
                    <Button link={process.env.PUBLIC_URL + "/contact"} name="Let's Build Together" className="filled blueTheme" style={{marginTop: "40px", width: "190px", height: "50px"}}></Button>
                </div>
            </div>
        </div>
    );
}

function HeroPortfolio(props){
    return(
        <div className={((props.bannerName)?(props.bannerName + " "):"") + "hero paddingB0"}>
            <div className="container">
                <h1>Unleash the <span className="colorWhite">astonishment</span></h1>
                <div className="descriptionText" style={{marginTop: "20px"}}>
                    Prepare to be astounded. Astun Gaming is not just a name; it's a promise. We're crafting gaming worlds that will
                    leave you breathless with innovative gameplay, stunning visuals, and mind-bending stories
                </div>
            </div>
        </div>
    );
}
                    
function HeroAbout(props){
    return(
        <div className={((props.bannerName)?(props.bannerName + " "):"") + "hero"}>
            <div className="container">
                <h1>Squad of gaming enthusiast on<br/><span className="colorWhite">a mission to create epic fun</span></h1>
            </div>
        </div>
    );
}


function Hero(props){
    if(props.bannerName === "homePageHero"){
        return HeroHome(props);
    }else if(props.bannerName === "aboutPageHero"){
        return HeroAbout(props);
    }else if(props.bannerName === "portfolioPageHero"){
        return HeroPortfolio(props);
    }else{
        return HeroHome(props);
    }
}
export default Hero;