import "./Style.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import JoinUs from "../components/JoinUs";
import WhyChooseUs from "../components/WhyChooseUs";
import Button from "../components/Button";


function Services(){
    return(
        <div className="page">
            <Header active="services" />
            
            <div className="section serviceSection">
                <div className="container">
                    <h1>What <span className="colorWhite">we do</span></h1>
                    <div className="descriptionText" style={{marginBottom:"30px"}}>
                        With a specialized product division, our company's experts can handle any type of task; 
                        our organization knows how to produce valuable apps that capture a large audience. 
                        Clients have every opportunity to immerse themselves into their products and contribute 
                        to well-designed prototypes that promote themselves.
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-12 col-md-1"></div>

                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/icon_GameDvelopment.svg" />
                                <div className="cardTitle">
                                    Game development
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/Icon_GamingFramework.svg" />                                
                                <div className="cardTitle">
                                    Gaming Framework
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/Icon_Hexagon.svg" />                                
                                <div className="cardTitle">
                                    Game simulators
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/Icon_Hexagon.svg" />                                    
                                <div className="cardTitle">
                                    Social casino solutions
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/Icon_Hexagon.svg" />                                    
                                <div className="cardTitle">
                                    Math magic
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-1"></div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-1"></div>

                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/icon_GameDvelopment.svg" />
                                <div className="cardTitle">
                                    HTML5, Unity
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/Icon_GamingFramework.svg" />                                
                                <div className="cardTitle">
                                    Cross platform compatibility
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/Icon_Hexagon.svg" />                                
                                <div className="cardTitle">
                                    Multiplayer gaming experience
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/Icon_Hexagon.svg" />                                    
                                <div className="cardTitle">
                                    Slot games, Card games, Table games
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div className="cardContainer">
                                <img src="./assets/images/icons/Icon_Hexagon.svg" />                                    
                                <div className="cardTitle">
                                    Live dealer games
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-1"></div>
                    </div>
                </div>
            </div>

            <div className="seperator"><div className="container"></div></div>

            <WhyChooseUs></WhyChooseUs>

            <div className="seperator"><div className="container"></div></div>

            <JoinUs></JoinUs>

            <Footer />
        </div>
    );
}

export default Services;