import "./Style.scss";

function IndiaHaryana(){
    return(
        <div>
            <h6 style={{color:"#AAA", fontWeight:"bold"}}>India (Headquarters):</h6>
            <p className="mt-1 mb-4">
                Astun Gaming,<br />
                361, Sector - 37,<br />
                Faridabad, Haryana (India)<br />
                Zip code - 121003
            </p>
        </div>
    );
}

function London(){
    return(
        <div>
            <h6>London:</h6>
            <p className="mt-1 mb-4">
                55, Bartholomew Close,<br/>
                London, ECIA 7ES, United Kingdom
            </p>
        </div>
    );
}

function Malta(){
    return(
        <div>
            <h6>Malta:</h6>
            <p className="mt-1 mb-4">
                66, Cathedral Street,<br/>
                Sliema SLM 1524, Malta
            </p>
        </div>
    );
}

function Address(attributes){
    return(
        <div>
            {attributes.heading? (<h4 className="text-uppercase" style={{color:"#AAA"}}>{attributes.heading}</h4>) : ""}
            {attributes.onlyHeadquarters?(
                <IndiaHaryana />
            ) : (
                <div>
                    <IndiaHaryana />
                    {/* <London />
                    <Malta /> */}
                </div>
            )}
        </div>
    );
}

export default Address;