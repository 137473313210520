import "./Style.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";


function PrivacyPolicy(){
    return(
        <div className="page">
            <Header active="about"/>

            <div className="pageBody">
                <div className="container">
                    <div className="row">

                        <div className="col-12">
                            <div className="pageHeading">
                                <h2>Privacy & Cookie Policy</h2>
                            </div>

                            Some content here...
                        </div>

                    </div>
                </div>
            </div>
            
            <Footer/>
        </div>
    );
}

export default PrivacyPolicy;