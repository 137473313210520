import "./Style.scss";

function WhyChooseUs(){
    return(
        <div className="section whyUsSection">
            <div className="container">
                <h1>We're <span className="colorWhite">different</span></h1>
                <div className="descriptionText" style={{marginBottom:"30px"}}>
                    Our focus is on building strong partnerships based on trust, collaboration, and mutual success. We offer comprehensive support, tailored solutions, and cutting-edge technology to help our clients thrive in the competitive gaming market.
                </div>
                <div className="row" style={{marginTop:"20px"}}>
                    <div className="col-sm-12 col-lg-4">
                        <div className="card">
                            <div className="cardTitle">
                                Proprietery Gaming Framework
                            </div>
                            <div className="cardDescription">
                                Our custom-built framework allows for unparalleled flexibility, scalability, and performance, ensuring seamless gameplay and endless possibilities.
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                        <div className="card">
                            <div className="cardTitle">
                                Player centriq approach
                            </div>
                            <div className="cardDescription">
                                We involve our community in the development process, ensuring our games meet the highest standards of quality, enjoyment, and player satisfaction.
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                        <div className="card">
                            <div className="cardTitle">
                                AI Driven Development
                            </div>
                            <div className="cardDescription">
                                We harness the power of AI to create dynamic, responsive, and personalized gaming experiences that adapt to each player's unique style.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default WhyChooseUs;