import { Link } from "react-router-dom";
import "./Style.scss";

function Logo(attributes){
    return(
        <div className={"logo" + " " + attributes.className} style={attributes.style}>
           <Link to={process.env.PUBLIC_URL}>
               <img src="./assets/images/logo-white.svg" style={{width: "100%"}} />
           </Link>
        </div>
    );
}
export default Logo;