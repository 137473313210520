import "./Style.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import JoinUs from "../components/JoinUs";
import Hero from "../components/Hero";
import WhyChooseUs from "../components/WhyChooseUs";
import Banner from "../components/Banner";


function About(){
    return(
        <div className="page">
            <Header active="about" />

            <Hero bannerName="aboutPageHero"></Hero>

            <div className="seperator"><div className="container"></div></div>

            <Banner></Banner>

            <div className="section aboutSection">
                <div className="container">
                    <div>
                        <h2 className="colorWhite">
                            Astun Gaming is not just a name; it’s a promise
                            <br></br>
                            Crafting gaming worlds that will leave you breathless
                        </h2>

                        <p style={{maxWidth:"760px", margin:"20px auto"}}>
                            With innovative gameplay, stunning visuals, and mind-bending stories, we’re redefining what it means to be immersed. 
                            We’re not just another gaming company but a squad of gaming enthusiasts on a mission to create epic fun. 
                            We’re the mad scientists of entertainment, mixing code, creativity, and caffeine to conduct gaming gold. 
                            Prepare to be astuound.
                        </p>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mt-5">
                            <h2 className="colorWhite">Our Vision</h2>
                            <p style={{maxWidth:"360px", margin:"auto"}}>
                                Is to Founded with a passion for delivering unparalleled entertainment to players worldwide, 
                                Astun Gaming has quickly established itself as a leading force in the online gaming industry. 
                                At Astun Gaming, we believe in.
                            </p>
                        </div>
                        <div className="col-md-6 mt-5">
                            <h2 className="colorWhite">Our Mission</h2>
                            <p style={{maxWidth:"360px", margin:"auto"}}>
                                Gaming solutions that captivate and excite players. Our team of experienced developers, designers, 
                                and gaming enthusiasts are dedicated to crafting immersive gaming experiences that keep players coming.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="seperator"><div className="container"></div></div>

            <div className="section aboutSection">
                <h1>Our core <span className="colorWhite">principles</span></h1>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-sm-12 col-lg-4">
                            <div className="cardContainer">
                                We <span style={{color:"white", fontWeight:"bold"}}>innovate fearlessly</span>, always pushing the boundaries of gaming.
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="cardContainer">
                                We <span style={{color:"white", fontWeight:"bold"}}>put players first</span>, crafting experiences that delight & surprises.
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="cardContainer">
                                We <span style={{color:"white", fontWeight:"bold"}}>collaborate as a team</span>, fueling each other’s creativity and passion.
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-lg-4">
                            <div className="cardContainer">
                                We <span style={{color:"white", fontWeight:"bold"}}>learn continuously</span>, embracing new ideas and technologies.
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="cardContainer">
                                We <span style={{color:"white", fontWeight:"bold"}}>have fun</span>, because gaming should be enjoyable for everyone.
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="cardContainer">
                                Providing <span style={{color:"white", fontWeight:"bold"}}>exceptional experiences</span> that exceed player expectations.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="seperator"><div className="container"></div></div>
    
            <WhyChooseUs></WhyChooseUs>

            <div className="seperator"><div className="container"></div></div>

            <JoinUs></JoinUs>

            <Footer />
        </div>
    );
}

export default About;