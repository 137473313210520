import "./Style.scss";


function Platforms(attributes){
    return(
        <div>
            {attributes.heading? (<h4 className="mt-4 text-uppercase" style={{color:"#AAA"}}>{attributes.heading}</h4>) : ""}
            
            <ul className="socialMediaLinks d-flex mb-3 mb-lg-0 mt-lg-0 m-auto m-md-0" style={{width: "fit-content"}}>
                <li>
                    <i className="bi bi-windows"></i>
                </li>
                <li>
                    <i className="bi bi-apple"></i>
                </li>
                <li>
                    <div style={{marginTop:"-4px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-unity" viewBox="0 0 16 16">
                            <path d="M15 11.2V3.733L8.61 0v2.867l2.503 1.466c.099.067.099.2 0 .234L8.148 6.3c-.099.067-.197.033-.263 0L4.92 4.567c-.099-.034-.099-.2 0-.234l2.504-1.466V0L1 3.733V11.2v-.033.033l2.438-1.433V6.833c0-.1.131-.166.197-.133L6.6 8.433c.099.067.132.134.132.234v3.466c0 .1-.132.167-.198.134L4.031 10.8l-2.438 1.433L7.983 16l6.391-3.733-2.438-1.434L9.434 12.3c-.099.067-.198 0-.198-.133V8.7c0-.1.066-.2.132-.233l2.965-1.734c.099-.066.197 0 .197.134V9.8z"/>
                        </svg>
                    </div>
                </li>
                <li>
                    <i className="bi bi-filetype-html"></i>
                </li>
            </ul>
        </div>
    );
}

export default Platforms;