import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Home from './pages/Home';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import Services from './pages/Services';
import Career from './pages/Career';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ScrollToTop from './components/ScrollToTop';


const root = createRoot(
	document.getElementById('root')
);

root.render(
	<BrowserRouter>
		<ScrollToTop />
		<Routes>
			<Route path={process.env.PUBLIC_URL} element={<Home />} />
			<Route path={process.env.PUBLIC_URL + "/games"} element={<Portfolio />} />
			<Route path={process.env.PUBLIC_URL + "/about"} element={<About />} />
			<Route path={process.env.PUBLIC_URL + "/services"} element={<Services />} />
			<Route path={process.env.PUBLIC_URL + "/career"} element={<Career />} />
			<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />} />
			<Route path={process.env.PUBLIC_URL + "/privacy"} element={<PrivacyPolicy />} />
			<Route path="*" element={<Home />} />
	    </Routes>
	</BrowserRouter>
);